import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@naf/button';
import { Text, TextVariant } from '@naf/text';
import { spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Lock } from '@styled-icons/material/Lock';
import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

const MustLogIn = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();

  const handleLogin = () => {
    loginWithRedirect({ appState: { returnTo: location.pathname } });
  };

  return (
    <div>
      <LogInText variant={TextVariant.ArticleText}>
        For å sende inn spørsmål er du nødt til å være logget inn.
      </LogInText>
      <LogInButton onClick={() => handleLogin()} variant="signature">
        <Text variant={TextVariant.Button}>Logg inn</Text>
        <Lock size={24} />
      </LogInButton>
    </div>
  );
};

const LogInText = styled(Text)`
  margin-top: 0;
  margin-bottom: 26px;
`;

const LogInButton = styled(Button)`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;

  padding: ${spacing.space16} ${spacing.space20} ${spacing.space16} ${spacing.space24};

  background-color: ${nafColor.signature.yellow};
  color: ${nafColor.signature.black};
  border-radius: 2px;

  & > span {
    column-gap: ${spacing.space12};
  }
`;

export { MustLogIn };
