import { Button } from '@naf/button';
import { Text, TextVariant } from '@naf/text';
import { spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { ArrowForward } from '@styled-icons/material/ArrowForward';
import axios from 'axios';
import React, { FormEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useAuth0Token } from '../../../../../hooks/useAuth0Token';
import useSelector from '../../../../../redux/typedHooks';

type SubmissionFormProps = { feedId: string; onSubmitSucceed: () => void };

const SubmissionForm = ({ feedId, onSubmitSucceed }: SubmissionFormProps) => {
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = useSelector((state) => state.application);
  const { simpleToken } = useAuth0Token();

  const [name, setName] = useState('');
  const [question, setQuestion] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const questionTooLong = question.length > 500;
  const questionTooShort = question.length < 10;

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsSubmitting(true);

      await axios.post(
        `${apimBaseUrl}/${apimNafNoApi}/qnafeed/${feedId}/ask`,
        { name, question },
        {
          headers: {
            'Ocp-Apim-Subscription-Key': apimContentHub,
            Authorization: simpleToken ? `Bearer ${simpleToken}` : undefined,
          },
        },
      );

      onSubmitSucceed();
    },
    [name, question, apimBaseUrl, apimContentHub, feedId, simpleToken, apimNafNoApi, onSubmitSucceed],
  );

  return (
    <form onSubmit={handleSubmit}>
      <Label htmlFor="name">
        <LabelText variant={TextVariant.Small}>Navn</LabelText>
        <NameInput
          type="text"
          name="name"
          placeholder="Ditt navn eller kallenavn..."
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Label>

      <Label htmlFor="question">
        <LabelText variant={TextVariant.Small}>Spørsmål</LabelText>
        <QuestionTextArea
          name="question"
          placeholder="Ditt spørsmål..."
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <LetterCounter tooLong={questionTooLong} variant={TextVariant.Small}>
          {question.length}/500 tegn
        </LetterCounter>
      </Label>

      <SubmitButton
        variant="secondary"
        type="submit"
        disabled={questionTooLong || questionTooShort || name.length < 2}
        isLoading={isSubmitting}
        loadingLabel="Sender inn..."
      >
        <Text variant={TextVariant.Button}>Send inn</Text>
        <SubmitIcon size={16} />
      </SubmitButton>

      <CaptionText variant={TextVariant.Caption}>
        Ikke alle spørsmål blir besvart, og useriøse innsendelser vil bli ignorert.
      </CaptionText>
    </form>
  );
};

const SubmitButton = styled(Button)`
  padding: ${spacing.space16} ${spacing.space20} ${spacing.space16} ${spacing.space24};
`;

const SubmitIcon = styled(ArrowForward)`
  margin-left: ${spacing.space12};
`;

const CaptionText = styled(Text)`
  margin-top: ${spacing.space8};
  margin-bottom: 0;
`;

const Label = styled.label`
  display: flex;
  flex-flow: column nowrap;
`;

const LabelText = styled(Text)`
  margin-top: 0;
  margin-bottom: ${spacing.space8};
`;

const NameInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 10px ${spacing.space12};
  margin-bottom: ${spacing.space24};

  border: 1px solid ${nafColor.neutral.neutral4};
  border-radius: 2px;
`;

const QuestionTextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: ${spacing.space80};
  padding: 10px ${spacing.space12};

  border: 1px solid ${nafColor.neutral.neutral4};
  border-radius: 2px;

  resize: none;
`;

const LetterCounter = styled(Text)<{ tooLong: boolean }>`
  margin-top: ${spacing.space8};
  margin-bottom: 0;
  color: ${({ tooLong }) => (tooLong ? nafColor.information.errorDark : nafColor.neutral.neutral4)};

  align-self: flex-end;
`;

export { SubmissionForm };
