import { useAuth0 } from '@auth0/auth0-react';
import { Text, TextVariant } from '@naf/text';
import { icons, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { KeyboardArrowDown } from '@styled-icons/material/KeyboardArrowDown';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MustLogIn } from './SubmitQuestionBox/MustLogIn';
import { NotPossible } from './SubmitQuestionBox/NotPossible';
import { SubmissionForm } from './SubmitQuestionBox/SubmissionForm';
import { ThankYou } from './SubmitQuestionBox/ThankYou';

type SubmitQuestionFormProps = {
  id: string;
  openForSubmissions: boolean;
  loginRequired: boolean;
};

enum ContentState {
  NotLoggedIn = 'NotLoggedIn',
  FormFillable = 'FormFillable',
  FormSubmitted = 'FormSubmitted',
  FeedClosed = 'FeedClosed',
}

const SubmitQuestionBox = ({ id, openForSubmissions, loginRequired }: SubmitQuestionFormProps) => {
  const { isAuthenticated } = useAuth0();

  const [open, setOpen] = useState(false);
  const [contentState, setContentState] = useState<ContentState>(ContentState.NotLoggedIn);

  useEffect(() => {
    if (!openForSubmissions) {
      // The feed isn't taking submissions - show feed closed screen
      if (!(contentState === ContentState.FeedClosed)) setContentState(ContentState.FeedClosed);
    } else if (loginRequired && !isAuthenticated) {
      // Feed is open, login is required and user isn't logged in - show login screen
      if (!(contentState === ContentState.NotLoggedIn)) setContentState(ContentState.NotLoggedIn);
    } else if (contentState === ContentState.NotLoggedIn || contentState === ContentState.FeedClosed) {
      // Feed is open, user is logged in or login is not required, and we're showing either the
      // closed screen or login screen - show question entry screen
      setContentState(ContentState.FormFillable);
    }
  }, [isAuthenticated, contentState, loginRequired, openForSubmissions]);

  return (
    <Box>
      <HeaderContent
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(!open);
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === ' ') setOpen(!open);
        }}
        tabIndex={0}
      >
        <Text style={{ margin: 0 }} variant={TextVariant.Header3}>
          Send inn spørsmål
        </Text>
        <IconWrap $isOpen={open}>
          <KeyboardArrowDown size={30} />
        </IconWrap>
      </HeaderContent>
      <BodyContentWrapper open={open}>
        <BodyContent feedId={id} contentState={contentState} setContentState={setContentState} />
      </BodyContentWrapper>
    </Box>
  );
};

type BodyContentProps = { feedId: string; contentState: ContentState; setContentState: (cs: ContentState) => void };

const BodyContent = ({ feedId, contentState, setContentState }: BodyContentProps) => {
  switch (contentState) {
    case ContentState.NotLoggedIn:
      return <MustLogIn />;
    case ContentState.FormFillable:
      return <SubmissionForm feedId={feedId} onSubmitSucceed={() => setContentState(ContentState.FormSubmitted)} />;
    case ContentState.FormSubmitted:
      return <ThankYou goBack={() => setContentState(ContentState.FormFillable)} />;
    case ContentState.FeedClosed:
      return <NotPossible />;
    default:
      return null;
  }
};

const Box = styled.div`
  padding: ${spacing.space24};
  border: 2px solid ${nafColor.primary.dew};
  border-radius: 2px;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  cursor: pointer;
`;

const BodyContentWrapper = styled.div<{ open: boolean }>`
  padding-top: ${({ open }) => (open ? spacing.space24 : '0')};
  height: ${({ open }) => (open ? 'auto' : '0')};

  overflow: hidden;
`;

const IconWrap = styled.div<{ $isOpen: boolean }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${spacing.space8};

  transition-duration: 0.3s;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0)')};

  svg {
    font-size: ${icons.m};
  }
`;

export { SubmitQuestionBox };
