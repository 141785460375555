import { useEffect, useRef } from 'react';

type Callback = (...x: any[]) => any;

function useInterval(callback: Callback, delay: number) {
  const savedCallback = useRef<Callback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    // Both the ts compiler and eslint cry if I don't include this empty return
    return; // eslint-disable-line no-useless-return, consistent-return
  }, [delay]);
}

export { useInterval };
