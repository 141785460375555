import { spacing } from '@naf/theme';
import axios from 'axios';
import React, { useState } from 'react';
import styled from 'styled-components';
import { QnaFeedBlockType, QnaFeedCheckForNewType, QnaFeedFetchMoreType } from '../../../../../types/qnaFeedBlockType';
import { useInterval } from '../../../hooks/useInterval';
import useSelector from '../../../redux/typedHooks';
import { HR_COLOR } from '../../../styles/colors';
import { Header } from './QnaFeedBlock/Header';
import { QnaList } from './QnaFeedBlock/QnaList';
import { ShowOlderMessages } from './QnaFeedBlock/ShowOlderMessages';
import { SubmitQuestionBox } from './QnaFeedBlock/SubmitQuestionBox';

type QnaFeedBlockProps = { block: QnaFeedBlockType };

const QnaFeedBlock = ({ block }: QnaFeedBlockProps) => {
  const { title, ingress, _id } = block;

  const { apimBaseUrl, apimNafNoApi, apimContentHub } = useSelector((state) => state.application);

  const [qnaEntries, setQnaEntries] = useState(block.qnaEntries || []);
  const [total, setTotal] = useState(block.feedLength || 0);
  const [openForSubmissions, setOpenForSubmissions] = useState(block.openForSubmissions);

  // Keep track of polling state
  const [newEntries, setNewEntries] = useState(0);

  const fetchOlder = async () => {
    const oldest = qnaEntries[qnaEntries.length - 1]?.publishedTime;
    const res = await axios.get<QnaFeedFetchMoreType>(
      `${apimBaseUrl}/${apimNafNoApi}/qnafeed/${_id}/more?before=${oldest || new Date().toISOString()}`,
      { headers: { 'Ocp-Apim-Subscription-Key': apimContentHub } },
    );

    if (res.data.length > 0) {
      const responseData = res.data[0];
      setQnaEntries([...qnaEntries, ...(responseData.qnaEntries || [])]);

      if (responseData.openForSubmissions !== openForSubmissions) {
        setOpenForSubmissions(responseData.openForSubmissions);
      }
    }
  };

  const fetchNewer = async () => {
    const newest = qnaEntries[0]?.publishedTime;
    const res = await axios.get<QnaFeedFetchMoreType>(
      `${apimBaseUrl}/${apimNafNoApi}/qnafeed/${_id}/more?after=${newest || new Date().toISOString()}`,
      { headers: { 'Ocp-Apim-Subscription-Key': apimContentHub } },
    );

    if (res.data.length > 0) {
      const responseData = res.data[0];
      setQnaEntries([...(responseData.qnaEntries || []), ...qnaEntries]);
      setNewEntries(0);
      setTotal(total + (responseData.qnaEntries?.length || 0));

      if (responseData.openForSubmissions !== openForSubmissions) {
        setOpenForSubmissions(responseData.openForSubmissions);
      }
    }
  };

  const checkForNew = async () => {
    const newest = qnaEntries[0]?.publishedTime;
    const res = await axios.get<QnaFeedCheckForNewType>(
      `${apimBaseUrl}/${apimNafNoApi}/qnafeed/${_id}/new?after=${newest || new Date().toISOString()}`,
      { headers: { 'Ocp-Apim-Subscription-Key': apimContentHub } },
    );

    if (res.data.length > 0) {
      const responseData = res.data[0];
      setNewEntries(responseData.newEntriesCount);

      if (responseData.openForSubmissions !== openForSubmissions) {
        setOpenForSubmissions(responseData.openForSubmissions);
      }
    }
  };

  useInterval(checkForNew, 15000);

  return (
    <div>
      <Header title={title} ingress={ingress} />
      <HRLine />
      <SubmitQuestionBox id={_id} openForSubmissions={openForSubmissions} loginRequired={block.loginRequired} />
      <QnaList entries={qnaEntries} fetchNewer={fetchNewer} amount={newEntries} />
      <ShowOlderMessages loaded={qnaEntries.length} total={total} onClick={fetchOlder} />
      <HRLine />
    </div>
  );
};

const HRLine = styled.hr`
  border: 0;
  border-top: 3px solid ${HR_COLOR};
  width: 96px;
  margin: ${spacing.space48} 0;
  color: ${HR_COLOR};

  :not(:last-child) {
    margin-left: 14px;
  }
`;

export { QnaFeedBlock };
