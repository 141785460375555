import React from 'react';

import { Text, TextVariant } from '@naf/text';

const NotPossible = () => (
  <Text variant={TextVariant.ArticleText} tag="span">
    Det er dessverre ikke mulig å sende inn spørsmål akkurat nå. Trenger du hjelp av kundeservice kan du åpne chat med
    ikonet nederst til høyre på siden.
  </Text>
);

export { NotPossible };
