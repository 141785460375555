import { Button } from '@naf/button';
import { spacing } from '@naf/theme';
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';

type ShowOlderMessagesProps = {
  loaded: number;
  total: number;
  onClick: () => void;
};

const ShowOlderMessages = ({ loaded, total, onClick }: ShowOlderMessagesProps) => (
  <ShowMoreContainer>
    <Button
      variant="secondary"
      type="button"
      disabled={loaded === total}
      onClick={(e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
    >
      Vis flere
    </Button>
    <TextContainer>
      Viser {loaded} av {total}
    </TextContainer>
  </ShowMoreContainer>
);

const ShowMoreContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  margin: ${spacing.space56} 0 -${spacing.space16};
`;

const TextContainer = styled.div`
  margin-left: ${spacing.space24};
`;

export { ShowOlderMessages };
