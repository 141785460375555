import React, { SyntheticEvent } from 'react';

import { Text, TextVariant } from '@naf/text';
import styled from 'styled-components';
import { spacing } from '@naf/theme';
import ButtonLink from '../../../../ButtonLink';

const ThankYou = ({ goBack }: { goBack: () => void }) => (
  <div>
    <div>
      <Text variant={TextVariant.ArticleTextHeader} tag="span">
        Tusen takk for ditt spørsmål!
      </Text>
      &nbsp;
      <Text variant={TextVariant.ArticleText} tag="span">
        Vi svarer på spørsmål fortløpende, men dessverre vil ikke alle bli besvart. Følg med i feeden om vi svarer på
        det du lurer på.
      </Text>
    </div>
    <BodyLink
      onClick={(e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        goBack();
      }}
      text="Send inn nytt spørsmål"
    />
  </div>
);

const BodyLink = styled(ButtonLink)`
  margin-left: 0;
  margin-top: ${spacing.space24};
`;

export { ThankYou };
